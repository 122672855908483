import { initAccordion } from './components/initAccordion';
import { initScrollAnimation } from './components/initScrollAnimation';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initSliders } from './components/initSliders';
import { initScrollLocker } from './assets/initScrollLocker';
import { initForms } from './components/initForms';
import { initMobMenu } from './components/initMobMenu';
import { initParallax } from './components/initParallax';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initAmplitude } from './components/initAmplitude';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

function handleOnLoadPage() {
    initLazyLoadFonts();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initHashEmail();
    initAccordion();
    initScrollAnimation();
    initScrollToAnchor();
    initSliders();
    initScrollLocker();
    initForms();
    initMobMenu();
    initParallax();
    initAmplitude();
}
